import React, { useContext } from "react";
import hamburger from "../img/hamburger.png";
import logo from "../img/logo.png";
import logoWhite from "../img/logo-white.png";
import { GlobalContext } from "../context/globalContext";
import { Hiddennavbar } from "./HiddenNavbar/Hiddennavbar";
import blockLogo from "../assets/logo.png";
import { useNavigate } from "react-router-dom";

export const Navbar = () => {
  let navigate = useNavigate();
  const { topNavbar, setTopNavbar, scrollPosition } = useContext(GlobalContext);
  const toggleNavbar = () => {
    setTopNavbar(!topNavbar);
  };

  return (
    <div>
      <nav
        id="Header"
        className={
          scrollPosition > 100
            ? "Header__container scrolledNavbar"
            : "Header__container"
        }
      >
        <div id="Hamburger" className="Header__hamburger">
          <a onClick={toggleNavbar}>
            <img
              src={hamburger}
              draggable="false"
              alt="hamburger"
              className="logoSizeReduce1"
              id="logoSizeReduce2"
            />
          </a>
        </div>

        {topNavbar ? (
          <div className="Header__logo" onClick={() => navigate("/")}>
            <a href="/legacy/#top">
              <img
                src={logo}
                draggable="false"
                alt="logo"
                className="logoSizeReduce1"
                id="logoSizeReduce1"
              />
            </a>
          </div>
        ) : (
          <div className="Header__logo" onClick={() => navigate("/")}>
            <a href="/legacy/#top">
              <img
                src={topNavbar ? logoWhite : logo}
                draggable="false"
                alt="logo"
                className="logoSizeReduce1"
                id="logoSizeReduce1"
              />
            </a>
          </div>
        )}

        {/* {topNavbar ? <Hiddennavbar /> : ""} */}
      </nav>
    </div>
  );
};
