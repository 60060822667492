import React, { useContext } from "react";
import { GlobalContext } from "../../context/globalContext";
import hamburger from "../../img/hamburger.png";
import logo from "../../img/logo.png";
import logoWhite from "../../img/logo-white.png";
import { Link, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

export const Content = () => {
  const { topNavbar, setTopNavbar } = useContext(GlobalContext);
  const toggleNavbar = () => {
    setTopNavbar(!topNavbar);
  };
  return (
    <div id="hiddenNavbarContent">
      <div
        style={{
          position: "absolute",
          top: "5%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={toggleNavbar}
      >
        <Link to="/legacy">
          <img
            src={logoWhite}
            draggable="false"
            alt="logo"
            className="hiddenNavbarLogo"
            id="logoSizeReduce3"
          />
        </Link>
      </div>
      <div id="hiddenNavbarContentlist">
        <HashLink to="/legacy/#services" onClick={toggleNavbar}>
          SERVICES
        </HashLink>

        <Link to="/legacy/portfolio/" onClick={toggleNavbar}>
          OUR PORTFOLIO
        </Link>

        <HashLink to="/legacy/#process" onClick={toggleNavbar}>
          OUR PROCESS
        </HashLink>

        <HashLink to="/legacy/#pricing" onClick={toggleNavbar}>
          PRICING MODEL
        </HashLink>

        <HashLink to="/legacy/#contact" onClick={toggleNavbar}>
          DISCUSS PROJECT
        </HashLink>
      </div>
      <button onClick={toggleNavbar} className="Header__menu__close"></button>
    </div>
  );
};
