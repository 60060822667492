import React from "react";
import nvestDivPage from "../../../img/nvest-main-page-alternative.png";
import nodejs from "../../../img/nodejs-logo.svg";
import js from "../../../img/js.svg";
import amazonWebServices from "../../../img/Amazon_Web_Services_Logo.svg";
import ubuntu from "../../../img/ubuntu.svg";
import apache from "../../../img/apache.svg";
import pm2 from "../../../img/pm2.svg";
import fabric from "../../../img/fabric.svg";
import bitcoin from "../../../img/bitcoin.png";
import ethereum from "../../../img/ethereum.svg";
import orbitdb from "../../../img/orbitdb.svg";
import couchdb from "../../../img/couchdb.svg";
import php from "../../../img/php.svg";
import go from "../../../img/go.svg";

import facebooKfBrands from "../../../img/facebook-f-brands.svg";
import linkedinInBrands from "../../../img/linkedin-in-brands.svg";

import { HashLink } from "react-router-hash-link";

export const NvestGlobal = () => {
  return (
    <div className="Site__container">
      <div className="project--nge">
        <section className="project">
          <div className="project__title">
            <h3>International cryptocurrency bank</h3>
            <h2> Nvest Global Enterprises</h2>
          </div>
          <div className="browser">
            <div className="browser--extrabar"></div>
            <div className="browser__screen">
              <img src={nvestDivPage} alt="screen" />
              <div></div>
            </div>
          </div>
        </section>
        <section className="project__description">
          <div className="project__descriptionBar"></div>
          <div className="project__spacer"></div>
          <div className="wrap">
            <p className="p p--large">
              Nvest Global Enterprises is a fintech company that is establishing
              a network fo brick-and-mortar cryptocurrency banks with the intent
              of disrupting retail banking. Nvest Online Banking is the
              cryptocurrency wallet management interface for Nvest’s retail
              banking customers.
            </p>
          </div>
          <div className="wrap backgroundDotted">
            <h3>Our Strategy</h3>
            <p className="p p--medium">
              Nvest Global Enterprises requested a service that could perform
              many of the same functions as a conventional bank: withdrawals,
              deposits, transfers, and balance monitoring--all from a mobile or
              desktop device.
            </p>
            <p className="p p--medium">
              The Nvest Online Banking platform offers users a clean, easy way
              to interact with Nvest’s cryptocurrency banking services while
              maintaining high levels of system security. The service also
              offers a way for customers to purchase financial products and
              derivatives directly on the platform.
            </p>
          </div>
          <div className="project__technologies">
            <div className="project__technologiesWrap">
              <div className="technology">
                <img src={nodejs} alt="" className="technology__logo" />
                <h5 className="technology__title">Node.js</h5>
              </div>
              <div className="technology__description">
                <h5>Node.js:</h5>
                <p className="p p--normal">
                  Node.js is a JavaScript runtime built on Chrome's V8
                  JavaScript engine and is designed to build scalable network
                  applications.
                </p>
                <a href="https://nodejs.org/en/">Read more</a>
                <div className="technology__descriptionPointer"></div>
              </div>

              <div className="technology">
                <img src={js} alt="" className="technology__logo" />
                <h5 className="technology__title">JavaScript</h5>
              </div>
              <div className="technology__description">
                <h5>JavaScript:</h5>
                <p className="p p--normal">
                  Along with HTML and CSS, JavaScript is one of the three core
                  technologies of the World Wide Web. JavaScript enables
                  interactive web pages and thus is an essential part of web
                  applications.
                </p>
                <a href="https://www.javascript.com">Read more</a>
                <div className="technology__descriptionPointer"></div>
              </div>

              <div className="technology">
                <img
                  src={amazonWebServices}
                  alt=""
                  className="technology__logo"
                />
                <h5 className="technology__title">AWS</h5>
              </div>
              <div className="technology__description">
                <h5>AWS:</h5>
                <p className="p p--normal">
                  Provides reliable on-demand cloud computing servers, resizable
                  compute capacity, and inexpensive data storage infrastructure.
                </p>
                <a href="https://aws.amazon.com">Read more</a>
                <div className="technology__descriptionPointer"></div>
              </div>

              <div className="technology">
                <img src={ubuntu} alt="" className="technology__logo" />
                <h5 className="technology__title">Ubuntu server</h5>
              </div>
              <div className="technology__description">
                <h5>Ubuntu server:</h5>
                <p className="p p--normal">
                  Provides reliable on-demand cloud computing servers, resizable
                  compute capacity, and inexpensive data storage infrastructure.
                </p>
                <a href="https://www.ubuntu.com">Read more</a>
                <div className="technology__descriptionPointer"></div>
              </div>

              <div className="technology">
                <img
                  src={apache}
                  alt=""
                  className="technology__logo technology__logo--wide"
                />
                <h5 className="technology__title">Apache HTTP</h5>
              </div>
              <div className="technology__description">
                <h5>Apache HTTP Server:</h5>
                <p className="p p--normal">
                  A free and open-source cross-platform web server. Apache HTTP
                  server is the most widely used HTTP server and is used by 43%
                  of all active websites.
                </p>
                <a href="https://httpd.apache.org">Read more</a>
                <div className="technology__descriptionPointer"></div>
              </div>

              <div className="technology">
                <img
                  src={pm2}
                  alt=""
                  className="technology__logo technology__logo--wide"
                />
                <h5 className="technology__title">PM2</h5>
              </div>
              <div className="technology__description">
                <h5>PM2:</h5>
                <p className="p p--normal">
                  A production runtime and process manager for Node.js
                  applications with a built-in load balancer. It allows
                  applications to run continuously and reload without downtime
                  while facilitating common devops tasks.
                </p>
                <a href="https://github.com/unitech/pm2">Read more</a>
                <div className="technology__descriptionPointer"></div>
              </div>

              <div className="technology">
                <img src={fabric} alt="" className="technology__logo" />
                <h5 className="technology__title">Hyperledger Fabric</h5>
              </div>
              <div className="technology__description">
                <h5>Hyperledger Fabric:</h5>
                <p className="p p--normal">
                  A foundation for developing applications and solutions with a
                  modular blockchain architecture. Hyperledger Fabric ease
                  implementing of components such as consensus and membership
                  services.
                </p>
                <a href="https://www.hyperledger.org/projects/fabric">
                  Read more
                </a>
                <div className="technology__descriptionPointer"></div>
              </div>

              <div className="technology">
                <img src={bitcoin} alt="" className="technology__logo" />
                <h5 className="technology__title">Bitcoin</h5>
              </div>
              <div className="technology__description">
                <h5>Bitcoin:</h5>
                <p className="p p--normal">
                  An experimental digital currency that enables instant payments
                  to anyone, anywhere in the world. Bitcoin uses peer-to-peer
                  technology to operate with no central authority and manages
                  transactions and money issuance collectively through its
                  network.
                </p>
                <a href="https://github.com/bitcoin/bitcoin">Read more</a>
                <div className="technology__descriptionPointer"></div>
              </div>

              <div className="technology">
                <img src={ethereum} alt="" className="technology__logo" />
                <h5 className="technology__title">Ethereum</h5>
              </div>
              <div className="technology__description">
                <h5>Ethereum:</h5>
                <p className="p p--normal">
                  A blockchain with a built-in Turing-complete programming
                  language, allowing anyone to write smart contracts and
                  decentralized applications and determine arbitrary rules for
                  ownership, transaction formats and state transition functions.
                </p>
                <a href="https://github.com/ethereum/wiki/wiki/White-Paper#ethereum">
                  Read more
                </a>
                <div className="technology__descriptionPointer"></div>
              </div>

              <div className="technology">
                <img
                  src={orbitdb}
                  alt=""
                  className="technology__logo technology__logo--wide"
                />
                <h5 className="technology__title">Orbit.DB</h5>
              </div>
              <div className="technology__description">
                <h5>Orbit.DB:</h5>
                <p className="p p--normal">
                  A serverless, distributed, peer-to-peer database. OrbitDB uses
                  IPFS as its data storage and automatically syncs databases
                  with its peers conflict-free, making it an excellent choice
                  for decentralized apps, blockchain applications and
                  offline-first web applications.
                </p>
                <a href="https://github.com/orbitdb/orbit-db">Read more</a>
                <div className="technology__descriptionPointer"></div>
              </div>

              <div className="technology">
                <img src={couchdb} alt="" className="technology__logo" />
                <h5 className="technology__title">Couch.DB</h5>
              </div>
              <div className="technology__description">
                <h5>Couch.DB:</h5>
                <p className="p p--normal">
                  An open source database software that focuses on ease of use
                  and having a scalable architecture. CouchDB scales from big
                  data to mobile and is designed for reliability.
                </p>
                <a href="http://couchdb.apache.org">Read more</a>
                <div className="technology__descriptionPointer"></div>
              </div>

              <div className="technology">
                <img src={php} alt="" className="technology__logo" />
                <h5 className="technology__title">PHP</h5>
              </div>
              <div className="technology__description">
                <h5>PHP:</h5>
                <p className="p p--normal">
                  A server-side scripting language designed for Web development
                  that is also used as a general-purpose programming language.
                </p>
                <a href="http://php.net">Read more</a>
                <div className="technology__descriptionPointer"></div>
              </div>

              <div className="technology">
                <img
                  src={go}
                  alt=""
                  className="technology__logo technology__logo--wide"
                />
                <h5 className="technology__title">Go</h5>
              </div>
              <div className="technology__description">
                <h5>Go:</h5>
                <p className="p p--normal">
                  Go is an relatively new programming language designed to
                  resolve common criticisms of other languages while maintaining
                  their positive characteristics. Go makes it easy to build
                  simple, reliable, and efficient software.
                </p>
                <a href="https://golang.org">Read more</a>
                <div className="technology__descriptionPointer"></div>
              </div>
            </div>
          </div>
        </section>
        <section className="project__callToAction">
          <div className="wrap">
            <h2>Need a successful project?</h2>
            <HashLink
              to="/legacy/#contact"
              title="Discuss your Project"
              className="button button--transparent"
            >
              WE CAN DO IT!
            </HashLink>
          </div>
        </section>
      </div>
      <section className="Footer__container --portfolio">
        <div className="Footer__content">
          <ul>
            <li>
              <a href="/legacy/#services" title="Services">
                Services
              </a>
            </li>
            <li>
              <a href="/legacy/portfolio" title="Our Portfolio">
                Our Portfolio
              </a>
            </li>
            <li>
              <a href="/legacy/#process" title="Our Collaboration Process">
                Our Process
              </a>
            </li>
            <li>
              <a href="/legacy/#pricing" title="Our Pricing Model">
                Pricing Model
              </a>
            </li>
            <li>
              <a href="/legacy/#contact" title="Discuss your Project">
                Contact
              </a>
            </li>
          </ul>
          <p>
            <a
              className="FooterSocial"
              href="https://www.facebook.com/GravitiChain/"
            >
              <button className="Hero__social__facebook">
                <img
                  src={facebooKfBrands}
                  draggable="false"
                  alt="facebooKfBrands"
                />
              </button>
            </a>
            <a
              className="FooterSocial"
              href="https://www.linkedin.com/company/gravitichain/"
            >
              <button className="Hero__social__linkedin">
                <img
                  src={linkedinInBrands}
                  draggable="false"
                  alt="linkedinInBrands"
                />
              </button>
            </a>
            © 2018 Gravitichain studios. All rights reserved.
            <br />
            <a
              className="FooterSocial --mobile"
              href="https://www.facebook.com/GravitiChain/"
            >
              <button className="Hero__social__facebook">
                <img
                  src={facebooKfBrands}
                  draggable="false"
                  alt="facebooKfBrands"
                />
              </button>
            </a>
            <a
              className="FooterSocial --mobile"
              href="https://www.linkedin.com/company/gravitichain/"
            >
              <button className="Hero__social__linkedin">
                <img
                  src={linkedinInBrands}
                  draggable="false"
                  alt="linkedinInBrands"
                />
              </button>
            </a>
          </p>
        </div>
      </section>
    </div>
  );
};
