import React from "react";
import acid from "../../../img/acid.png";
import js from "../../../img/js.svg";
import amazonWebServicesLogo from "../../../img/Amazon_Web_Services_Logo.svg";
import ubuntu from "../../../img/ubuntu.svg";
import apache from "../../../img/apache.svg";
import php from "../../../img/php.svg";
import facebooKfBrands from "../../../img/facebook-f-brands.svg";
import linkedinInBrands from "../../../img/linkedin-in-brands.svg";
import { HashLink } from "react-router-hash-link";

export const AcidMarketing = () => {
  return (
    <div className="Site__container">
      <div className="project--acid">
        <section className="project">
          <div className="project__title">
            <h3>Marketing and Social Media</h3>
            <h2>ACID Marketing</h2>
          </div>
          <div className="browser browser--noScroll">
            <div className="browser--extrabar"></div>
            <div className="browser__screen">
              <img src={acid} alt="screen" />
              <div></div>
            </div>
          </div>
        </section>
        <section className="project__description">
          <div className="project__descriptionBar"></div>
          <div className="project__spacer"></div>
          <div className="wrap">
            <p className="p p--large">
              A Canadian marketing company focused on social media and community
              management for startups, ICOs, and small businesses.
            </p>
          </div>
          <div className="wrap backgroundDotted">
            <h3>Our Strategy</h3>
            <p className="p p--medium">
              ACID wanted a visually striking website that could convert leads
              into customers.
            </p>
            <p className="p p--medium">
              We pulled inspiration from four different award winning websites
              to produce a next-level website. ACID combines a unique
              horizontally scrolling website with impressive animations and a
              complementary responsive mobile application.
            </p>
          </div>
          <div className="project__technologies">
            <div className="project__technologiesWrap">
              <div className="technology">
                <img src={js} alt="" className="technology__logo" />
                <h5 className="technology__title">JavaScript</h5>
              </div>
              <div className="technology__description">
                <h5>JavaScript:</h5>
                <p className="p p--normal">
                  Along with HTML and CSS, JavaScript is one of the three core
                  technologies of the World Wide Web. JavaScript enables
                  interactive web pages and thus is an essential part of web
                  applications.
                </p>
                <a href="https://www.javascript.com">Read more</a>
                <div className="technology__descriptionPointer"></div>
              </div>

              <div className="technology">
                <img
                  src={amazonWebServicesLogo}
                  alt=""
                  className="technology__logo"
                />
                <h5 className="technology__title">AWS</h5>
              </div>
              <div className="technology__description">
                <h5>AWS:</h5>
                <p className="p p--normal">
                  Provides reliable on-demand cloud computing servers, resizable
                  compute capacity, and inexpensive data storage infrastructure.
                </p>
                <a href="https://aws.amazon.com">Read more</a>
                <div className="technology__descriptionPointer"></div>
              </div>

              <div className="technology">
                <img src={ubuntu} alt="" className="technology__logo" />
                <h5 className="technology__title">Ubuntu server</h5>
              </div>
              <div className="technology__description">
                <h5>Ubuntu server:</h5>
                <p className="p p--normal">
                  Provides reliable on-demand cloud computing servers, resizable
                  compute capacity, and inexpensive data storage infrastructure.
                </p>
                <a href="https://www.ubuntu.com">Read more</a>
                <div className="technology__descriptionPointer"></div>
              </div>

              <div className="technology">
                <img
                  src={apache}
                  alt=""
                  className="technology__logo technology__logo--wide"
                />
                <h5 className="technology__title">Apache HTTP</h5>
              </div>
              <div className="technology__description">
                <h5>Apache HTTP Server:</h5>
                <p className="p p--normal">
                  A free and open-source cross-platform web server. Apache HTTP
                  server is the most widely used HTTP server and is used by 43%
                  of all active websites.
                </p>
                <a href="https://httpd.apache.org">Read more</a>
                <div className="technology__descriptionPointer"></div>
              </div>

              <div className="technology">
                <img src={php} alt="" className="technology__logo" />
                <h5 className="technology__title">PHP</h5>
              </div>
              <div className="technology__description">
                <h5>PHP:</h5>
                <p className="p p--normal">
                  A server-side scripting language designed for Web development
                  that is also used as a general-purpose programming language.
                </p>
                <a href="http://php.net">Read more</a>
                <div className="technology__descriptionPointer"></div>
              </div>
            </div>
          </div>
        </section>
        <section className="project__callToAction">
          <div className="wrap">
            <h2>Need a successful project?</h2>
            <HashLink
              to="/#contact"
              title="Discuss your Project"
              className="button button--transparent"
            >
              WE CAN DO IT!
            </HashLink>
          </div>
        </section>
      </div>
      <section className="Footer__container --portfolio">
        <div className="Footer__content">
          <ul>
            <li>
              <a href="/legacy/#services" title="Services">
                Services
              </a>
            </li>
            <li>
              <a href="/legacy/portfolio" title="Our Portfolio">
                Our Portfolio
              </a>
            </li>
            <li>
              <a href="/legacy/#process" title="Our Collaboration Process">
                Our Process
              </a>
            </li>
            <li>
              <a href="/legacy/#pricing" title="Our Pricing Model">
                Pricing Model
              </a>
            </li>
            <li>
              <a href="/legacy/#contact" title="Discuss your Project">
                Contact
              </a>
            </li>
          </ul>
          <p>
            <a
              className="FooterSocial"
              href="https://www.facebook.com/GravitiChain/"
            >
              <button className="Hero__social__facebook">
                <img
                  src={facebooKfBrands}
                  draggable="false"
                  alt="facebooKfBrands"
                />
              </button>
            </a>
            <a
              className="FooterSocial"
              href="https://www.linkedin.com/company/gravitichain/"
            >
              <button className="Hero__social__linkedin">
                <img
                  src={linkedinInBrands}
                  draggable="false"
                  alt="linkedinInBrands"
                />
              </button>
            </a>
            © 2018 Gravitichain studios. All rights reserved.
            <br />
            <a
              className="FooterSocial --mobile"
              href="https://www.facebook.com/GravitiChain/"
            >
              <button className="Hero__social__facebook">
                <img
                  src={facebooKfBrands}
                  draggable="false"
                  alt="facebooKfBrands"
                />
              </button>
            </a>
            <a
              className="FooterSocial --mobile"
              href="https://www.linkedin.com/company/gravitichain/"
            >
              <button className="Hero__social__linkedin">
                <img
                  src={linkedinInBrands}
                  draggable="false"
                  alt="linkedinInBrands"
                />
              </button>
            </a>
          </p>
        </div>
      </section>
    </div>
  );
};
