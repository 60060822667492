import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";

import { Content } from "./components/HiddenNavbar/Content";
import { Hiddennavbar } from "./components/HiddenNavbar/Hiddennavbar";
import { MainContainer } from "./components/MainContainer";
import { Navbar } from "./components/Navbar";
import { Portfolio } from "./components/pages/Portfolio";
import { AcidMarketing } from "./components/pages/portfolio_pages/AcidMarketing";
import { GlobalXChange } from "./components/pages/portfolio_pages/GlobalXChange";
import { NvestBank } from "./components/pages/portfolio_pages/NvestBank";
import { NvestBanking } from "./components/pages/portfolio_pages/NvestBanking";
import { NvestGlobal } from "./components/pages/portfolio_pages/NvestGlobal";
import { SharedNotes } from "./components/pages/portfolio_pages/SharedNotes";
import { Stably } from "./components/pages/portfolio_pages/Stably";
import { mobilePortfolio } from "./data/mobileportfoliodata";
import { Sitebg } from "./components/Sitebg";
import { GlobalContext } from "./context/globalContext";
import HomePage from "./components/pages/HomePage";
import PaymentPage from "./components/pages/PaymentPage";
import CaseStudyPage from "./components/pages/CaseStudyPage";

function App() {
  const [topNavbar, setTopNavbar] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [mobilePortfolioData, setMobilePortfolioData] = useState();
  const [portfolioData, setPortfolioData] = useState([]);
  const [selectedProfileData, setSelectedProfileData] = useState([]);

  const value = {
    topNavbar,
    setTopNavbar,
    scrollPosition,
    mobilePortfolioData,
    setMobilePortfolioData,
    portfolioData,
    setPortfolioData,
    selectedProfileData,
    setSelectedProfileData,
  };

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
    if (position > 100) {
      if (!document.querySelector(".Header__container")) {
        document.querySelector(".Header__container").className =
          "scrolledNavbar";
      }
      document.getElementById("logoSizeReduce1").style.width = "50px";
      document.getElementById("logoSizeReduce1").style.marginTop = "5px";
      document.getElementById("logoSizeReduce2").style.marginTop = "-10px";
    }
    if (position < 100) {
      var element = document.getElementById("Header");
      console.log("works", element, position);
      element.classList.remove("scrolledNavbar");
      document.getElementById("logoSizeReduce1").style.width = "100%";
      document.getElementById("logoSizeReduce1").style.marginTop = "0px";
      document.getElementById("logoSizeReduce2").style.marginTop = "0px";
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    setMobilePortfolioData(mobilePortfolio[0]);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <GlobalContext.Provider value={value}>
      <div className="Site__container">
        {window.location.pathname.includes("legacy") ? <Sitebg /> : ""}

        {topNavbar ? (
          <div id="hidden" style={{ top: "0" }}>
            <Hiddennavbar />
            <Content />
          </div>
        ) : (
          <div id="hidden">
            <Hiddennavbar />
            <Content />
          </div>
        )}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/payment" element={<PaymentPage />} />
          <Route path="/casestudies/:casestudy" element={<CaseStudyPage />} />
          <Route path="/legacy" element={<MainContainer />} />
          <Route path="/legacy/portfolio" element={<Portfolio />} />
          <Route
            path="/legacy/portfolio/nvestbanking/"
            element={<NvestBanking />}
          />
          <Route
            path="/legacy/portfolio/sharednodes/"
            element={<SharedNotes />}
          />
          <Route
            path="/legacy/portfolio/globalxchange/"
            element={<GlobalXChange />}
          />
          <Route path="/legacy/portfolio/stably/" element={<Stably />} />
          <Route
            path="/legacy/portfolio/nvestglobal/"
            element={<NvestGlobal />}
          />
          <Route
            path="/legacy/portfolio/acidmarketing/"
            element={<AcidMarketing />}
          />
          <Route path="/legacy/portfolio/nvestbank/" element={<NvestBank />} />
        </Routes>
        {window.location.pathname.includes("legacy") ? <Navbar /> : ""}
      </div>
    </GlobalContext.Provider>
  );
}

export default App;
