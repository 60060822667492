import React, { useState, useEffect, useRef, useContext } from "react";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { GlobalContext } from "../../../context/globalContext";
import "./homepage.scss";

//images
import arrowDown from "../../../img/Homepage/arrowDown.svg";
import rightArrow from "../../../img/Homepage/rightArrow.svg";
import leftArrow from "../../../img/Homepage/leftArrow.svg";
import supernovaWhite from "../../../assets/enterprisese.svg";
import pointIcon from "../../../img/Homepage/point.svg";
import pointIconWhite from "../../../img/Homepage/pointWhite.svg";
import logo from "../../../img/Homepage/garagelogo.svg";
import logo1 from "../../../img/Homepage/starfishlogo2.svg";
import footerlogo from "../../../img/Homepage/footerLogo1.svg";
import dummy1 from "../../../img/Homepage/dummy1.svg";
import dummy2 from "../../../img/Homepage/dummy2.svg";
import fb from "../../../img/Homepage/fb.svg";
import twitter from "../../../img/Homepage/twitter.svg";
import insta from "../../../img/Homepage/insta.svg";
import linkedin from "../../../img/Homepage/linkedin.svg";
import whatsapp from "../../../img/Homepage/whatsapp.svg";
import telegram from "../../../img/Homepage/telegram.svg";
import utube from "../../../img/Homepage/utube.svg";
import one from "../../../img/Homepage/one.svg";
import two from "../../../img/Homepage/two.svg";
import three from "../../../img/Homepage/three.svg";
import four from "../../../img/Homepage/four.svg";
import five from "../../../img/Homepage/five.svg";
import six from "../../../img/Homepage/six.svg";
import footerLogoMob from "../../../img/MobileHomepage/footerLogoMob.svg";
import mobHero from "../../../img/MobileHomepage/mobHero.svg";
import air from "../../../img/Homepage/air.svg";
import naavi from "../../../img/Homepage/naavi.svg";
import nirvana from "../../../img/Homepage/nirvana.svg";
import ii from "../../../img/Homepage/ii.svg";
import garage from "../../../img/Homepage/garage.svg";
import web3 from "../../../img/Homepage/web3.svg";
import shorupan from "../../../img/Homepage/shorupan.svg";
import shorupan1 from "../../../img/Homepage/shorupan1.svg";
import shorupan2 from "../../../img/Homepage/shorupan.png";
import shorupan3 from "../../../img/Homepage/s4.svg";
import bullet from "../../../img/Homepage/bullet.png";
import arrWhite from "../../../img/MobileHomepage/arrWhite.svg";
import arrBlack from "../../../img/MobileHomepage/arrBlack.svg";
import discuss from "../../../img/Homepage/discuss.svg";
import marketsverse from "../../../img/Homepage/marketsverse.svg";
import marketsverseColor from "../../../img/Homepage/marketsverseColor.svg";
import publications from "../../../img/Homepage/publications.svg";
import publicationsColor from "../../../img/Homepage/publicationsColor.svg";
import malls from "../../../img/Homepage/malls.svg";
import mallsColor from "../../../img/Homepage/mallsColor.svg";
import firms from "../../../img/Homepage/firms.svg";
import firmsColor from "../../../img/Homepage/firmsColor.svg";
import classrooms from "../../../img/Homepage/classrooms.svg";
import classroomsColor from "../../../img/Homepage/classroomsColor.svg";
import vv from "../../../img/Homepage/vv.svg";
import vvColor from "../../../img/Homepage/vvColor.svg";

import supernova from "../../../img/Homepage/popupImg1.svg";
import startupBrokers from "../../../assets/nova.svg";
import onemdStartup from "../../../img/Homepage/popupImg3.svg";

import shorupanLink1 from "../../../img/Homepage/shorupanLink1.svg";
import shorupanLink2 from "../../../img/Homepage/shorupanLink2.svg";
import shorupanLink3 from "../../../img/Homepage/shorupanLink3.svg";

import arrowRight from "./navIcons/navArrowRight.svg";
import card1Image from "../../../img/Homepage/starfishlogo2.svg";
import card2Image from "./navIcons/navCardIcon2.svg";
import card3Image from "./navIcons/navCardIcon3.svg";
import card4Image from "./navIcons/navCardIcon41.svg";
import card5Image from "./navIcons/navCardIcon4.svg";
import card6Image from "./navIcons/navCardIcon5.svg";
import card7Image from "./navIcons/navCardIcon6.svg";
import card8Image from "./navIcons/navCardIcon7.svg";

const HomePage = () => {
  const brands = [
    {
      name: "supernova",
      logo: supernova,
      text: "Supernova is the official product development program for the Garage Operating System. We have helped startups across the world get their ideas to the market in record times without record costs. Our team specializes in bringing together all the skills you would otherwise purchase individually.",
      link: "",
    },
    {
      name: "startupBrokers",
      logo: startupBrokers,
      text: "StartupBrokers is an opportunity that allows non-founders to earn from the startup ecosystem. By becoming a certified StartupBroker, you will be able to help companies to find the services they need to grow their business and you get a revenue share of all transactions in your brokerage.",
      link: "https://startupbrokers.com",
    },
    // {
    //   name: "onemdStartup",
    //   logo: onemdStartup,
    //   text: "The #1MillionDollarStartup is an online course which teaches you how to convert your passion or idea into a profitable business which has a valuation of 1,000,000.00 USD within 6 months. The program uses the power of the Garage app to launch your product and scale your revenue generation until you hit the 7 figure club.",
    //   link: "",
    // },
  ];

  const navigate = useNavigate();
  const overlayRef = useRef(null);
  const [department, setDepartment] = useState("Research & Development");
  const [wwOption, setWwOption] = useState("Client Web App");
  const [hoveredLogo, setHoveredLogo] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState(brands[0]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdownMobile, setShowDropdownMobile] = useState(false);
  const [selectedDropdown, setSelectedDropdown] = useState("Product Dev");
  const [hoveredDropDown, setHoveredDropDown] = useState(selectedDropdown);
  const divRef = useRef(null);
  const [divSize, setDivSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (divRef.current) {
      const { width, height } = divRef.current.getBoundingClientRect();
      setDivSize({ width, height });
    }
  }, []);
  const {
    portfolioData,
    setPortfolioData,
    selectedProfileData,
    setSelectedProfileData,
  } = useContext(GlobalContext);
  console.log("divSize", divSize)
  const boxesData = [
    {
      title: "Submit Your Idea",
      subhead:
        "Fill out our simple form Here. Tell us what your startup intends to build and the size of your current operation.",
      icon: one,
    },
    {
      title: "Virtual Pitch (Business)",
      subhead:
        "If we like your vision, you will be requested to perform a 15 minute pitch via Zoom in front of one of our analysts.",
      icon: two,
    },
    {
      title: "Tech Deep Dive",
      subhead:
        "If the pitch goes well, then you will conduct a call with one of our senior technology executives to unpack the software you envision to build.",
      icon: three,
    },
    {
      title: "Starfish Term Sheet",
      subhead:
        "After internal deliberation, Starfish may issue you the standard STA Term Sheet. After which you will have 48 hrs to accept or reject the offer.",
      icon: four,
    },
    {
      title: "We Build Your Product",
      subhead:
        "Over the next 6 months, Marketsverse will build the first version of your product while Starfish gets you ready for your capital raise/product launch.",
      icon: five,
    },
    {
      title: "Share Buy Back Upon Launch",
      subhead:
        "Once your product is ready to launch you can choose to buy back the shares you gave to Starfish or keep us onboard into your launch.",
      icon: six,
    },
  ];

  const qnAndAns = [
    {
      ques: "What are we?",
      answer:
        "Gravitichain is an ecosystem-based intelligence firm, part of the startup studio Starfish. We specialize in providing growth solutions to startups at any stage, offering end-to-end support to help them scale from ideation to market launch.",
    },
    {
      ques: "What we do?",
      answer:
        "At Gravitichain, we establish customized sales and marketing strategies tailored to each client's unique needs, industry, and target audience. Our focus is on propelling startups to achieve rapid and sustainable growth in their respective markets.",
    },
    {
      ques: "How we do?",
      answer:
        "We combine our expertise in branding, marketing, growth strategy, and lead generation to create a comprehensive approach. By leveraging our vast network of investors, industry experts, and in-house tools, we nurture startups into thriving brands with strong brand identities and increased revenues. Through personalized solutions and a dedicated team, we empower startups to defy gravity and amplify their scale in the business cosmos.",
    },
  ];

  const testimonialData = [
    {
      pic: dummy1,
      content:
        "I couldn't be happier with the results Gravitichain delivered. They not only helped us reach our target audience but also provided invaluable insights that helped us optimize our marketing efforts.",
    },
    {
      pic: dummy2,
      content:
        "I couldn't be happier with the results Gravitichain delivered. They not only helped us reach our target audience but also provided invaluable insights that helped us optimize our marketing efforts.",
    },
    {
      pic: dummy1,
      content:
        "I couldn't be happier with the results Gravitichain delivered. They not only helped us reach our target audience but also provided invaluable insights that helped us optimize our marketing efforts.",
    },
    {
      pic: dummy2,
      content:
        "I couldn't be happier with the results Gravitichain delivered. They not only helped us reach our target audience but also provided invaluable insights that helped us optimize our marketing efforts.",
    },
    {
      pic: dummy1,
      content:
        "I couldn't be happier with the results Gravitichain delivered. They not only helped us reach our target audience but also provided invaluable insights that helped us optimize our marketing efforts.",
    },
  ];

  const appsLogo = [
    {
      id: 1,
      logo: marketsverse,
      colorLogo: marketsverseColor,
      link: "https://marketsverse.com",
    },
    {
      id: 2,
      logo: publications,
      colorLogo: publicationsColor,
      link: "https://publications.app",
    },
    {
      id: 3,
      logo: malls,
      colorLogo: mallsColor,
      // link: "https://malls.app",
      // link: "",
    },
    {
      id: 4,
      logo: firms,
      colorLogo: firmsColor,
      // link: "https://firms.app",
      // link: "",
    },
    {
      id: 5,
      logo: classrooms,
      colorLogo: classroomsColor,
      link: "https://classrooms.app",
    },
    {
      id: 6,
      logo: vv,
      colorLogo: vvColor,
      link: "https://viral.group",
    },
  ];

  const dropdownItems = [
    {
      id: 1,
      name: "Product Dev",
      cards: [
        {
          title: "Supernova",
          desc: "Build your tech from scratch with our end to end development offering.",
          link: "https://unlockgarage.com",
          img: card1Image,
        },
        {
          title: "Marketsverse",
          desc: "Choose from a set of pre-built application templates for your business.",
          link: "https://marketsverse.com",
          img: card2Image,
        },
      ],
    },
    {
      id: 2,
      name: "Marketing",
      cards: [
        {
          title: "Viralverse",
          desc: "Get affiliates and influencers to promote your product by listing it on Viralverse.",
          link: "https://viral.group",
          img: card3Image,
        },
      ],
    },
    {
      id: 3,
      name: "Sales",
      cards: [
        {
          title: "Socially",
          desc: "Hire, train and manage a completely remote sales team for your product.",
          link: "https://socially.app",
          img: card4Image,
        },
      ],
    },
    {
      id: 4,
      name: "Investor Relations",
      cards: [
        {
          title: "VCFunnel",
          desc: "Develop and automate you pitch process by hiring remote investment bankers.",
          // link: "https://vcfunnel.com",
          // link: "",
          img: card5Image,
        },
        {
          title: "IndianInvestor",
          desc: "Empower your customers to become your shareholders by digitalizing your cap table.",
          link: "https://indianinvestor.com",
          img: card6Image,
        },
      ],
    },
    {
      id: 5,
      name: "Legal/Accounting",
      cards: [
        {
          title: "Accountants.io",
          desc: "Automated finance department - fin statements, tax fillings & bookkeeping.",
          link: "https://accountants.io",
          img: card7Image,
        },
        {
          title: "InstaLegal",
          desc: "Automated legal department-contracts, advice, regulation & representation.",
          // link: "https://instalegal.com",
          // link: "",
          img: card8Image,
        },
      ],
    },
  ];

  const playerConfig = {
    youtube: {
      playerVars: {
        // Disable controls and showinfo (the video title and player actions).
        controls: 0,
        showinfo: 0,
        modestbranding: 1,
        loop: 1,
      },
    },
  };

  const specialityData = [
    {
      id: 0,
      heading: "Digital </br> Transformation",
      points: [
        "Technology & Ecosystem Audit",
        "Digital Strategy",
        "Digital Marketing",
        "Digital Analytics",
      ],
    },
    {
      id: 1,
      heading: "UI/UX & Design </br> Thinking",
      points: ["Branding", "UX Strategy", "Mobile & Web Design"],
    },
    {
      id: 2,
      heading: "Web </br> Development",
      points: [
        "Custom Web Applications",
        "E-Commerce Platforms",
        "Content Management Systems",
        "Dashboard Development",
      ],
    },
    {
      id: 3,
      heading: "Mobile </br> Engineering",
      points: [
        "Android & IOS Development",
        "React Native Development",
        "Wearable Development",
        "Hybrid Development",
      ],
    },
  ];

  const handlePrev = (item) => {
    let index = brands.indexOf(item);
    if (index === 0) {
      setSelectedBrand(brands[brands.length - 1]);
    } else {
      setSelectedBrand(brands[index - 1]);
    }
  };
  const handleNext = (item) => {
    let index = brands.indexOf(item);
    if (index === brands.length - 1) {
      setSelectedBrand(brands[0]);
    } else {
      setSelectedBrand(brands[index + 1]);
    }
  };

  useEffect(() => {
    axios
      .get("https://counsel.apimachine.com/api/getPortfolio")
      .then((response) => {
        let result = response?.data?.data;
        // console.log(result, "portfolio result");
        setPortfolioData(result);
      })
      .catch((error) => {
        console.log(error, "Error in getting portfolio data");
      });
  }, []);

  const scrollToTop = () => {
    const parentDiv = document.querySelector("#parent-div");
    if (parentDiv) {
      parentDiv.scrollTop = 0;
    }
  };

  return (
    <div className="homepage-container">
      <div className="homepage-navbar">
        <div className="navbar">
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={logo}
              alt=""
              onClick={() => {
                scrollToTop();
              }}
            />
            <div
              className="navDropDown"
              onClick={(e) => setShowDropdown(!showDropdown)}
            >
              {selectedDropdown?.toUpperCase()}
              <img
                src={arrowDown}
                alt=""
                style={{
                  width: "8.5px",
                  height: "6.5px",
                  marginLeft: "10px",
                  cursor: "pointer",
                  // contain
                  objectFit: "contain",
                  transform: showDropdown ? "rotate(180deg)" : "",
                }}
              />
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div
              className="hero-btn"
              onClick={(e) => {
                //open url
                window.open(
                  "https://calendly.com/vivek-145/supernova-tech-incubation-discussion?month=2023-09",
                  "_blank"
                );
              }}
            >
              Book Meeting
            </div>
            <div
              className="hero-btn-supernova"
              onClick={(e) => setShowPopup(true)}
            >
              <img src={supernovaWhite} alt="" style={{ width: "11rem" }} />
              <img
                src={arrowDown}
                alt=""
                style={{
                  transform: showPopup ? "rotate(180deg)" : "",
                  width: "10px",
                  height: "10px",
                }}
              />
            </div>
            <div
              className="hero-btn-nav"
              onClick={(e) => {
                window.open(
                  "https://calendly.com/vivek-145/supernova-tech-incubation-discussion?month=2023-09",
                  "_blank"
                );
              }}
            >
              Book Meeting
            </div>
          </div>
        </div>
        <div className="navbar-line"></div>
      </div>

      <div className="scrollable-container" id="parent-div">
        <div className="bgImg-container">
          <div className="bg-img">
            <div className="overlay-effect"></div>
            <ReactPlayer
              // style={{ display: window.innerWidth > 768 ? "block" : "none" }}
              className="react-player"
              url="./videoplayback.mp4"
              controls={false} // Set to false to hide the default player controls
              // config={playerConfig}
              width="100vw"
              height="100vh"
              playing
              muted
              loop
            />
          </div>

          <div className="hero-container">
            <div className="hero-text-div">
              <div className="intro-text">
                INTRODUCING{" "}
                <span>
                  <img src={logo1} alt="" />
                </span>{" "}
                SUPERNOVA
              </div>
              <div className="mob-intro-text">
                <span>
                  <img src={logo1} alt="" />
                </span>{" "}
                SUPERNOVA
              </div>
              <div className="hero-text">
                We Will Build Your Tech Startup For You In 180 Days
              </div>
              <div className="hero-text-mob">
                Build You’re Startup Within Six Months
              </div>
              <div className="hero-sub-text">
                Worried About the Time and Effort Needed to Turn Your Idea into
                a Reality?
              </div>
              <div
                className="hero-mob-img"
                onClick={(e) => {
                  window.open(
                    "https://calendly.com/vivek-145/supernova-tech-incubation-discussion?month=2023-09",
                    "_blank"
                  );
                }}
                style={{ fontWeight: 600 }}
              >
                Book Meeting
                {/* <img
                  src={mobHero}
                  alt=""
                  onClick={(e) => {
                    window.open(
                      "https://calendly.com/vivek-145/supernova-tech-incubation-discussion?month=2023-09",
                      "_blank"
                    );
                  }}
                /> */}
              </div>
              <div className="hero-btn-mob">Get Started Now</div>
            </div>

            <div className="hero-img">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <img
                  src={discuss}
                  alt=""
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    window.open(
                      "https://calendly.com/vivek-145/supernova-tech-incubation-discussion?month=2023-09",
                      "_blank"
                    );
                  }}
                />
              </div>
            </div>
          </div>

        </div>

        <div className="apps-container">
          {appsLogo.map((item, i) => {
            return (
              <div
                key={i}
                onMouseEnter={(e) => setHoveredLogo(item)}
                onMouseLeave={(e) => setHoveredLogo(null)}
              >
                <img
                  onClick={() =>
                    item?.link ? window.open(item.link, "_blank") : null
                  }
                  src={hoveredLogo?.id === item.id ? item.colorLogo : item.logo}
                  alt=""
                />
              </div>
            );
          })}
        </div>
        <div className="choose-container">
          <div className="choose-text-container">
            <div className="choose-text1">Why</div>
            <div className="choose-text2 gradient-text">Choose Us?</div>
          </div>
          <div className="mob-choose-container">
            <div className="why-text">Why</div>
            <div className="chooseus-text gradient-text">Choose Us?</div>
            <div className="mob-supernova-txt">
              Supernova is the official product development program for the
              Garage Operating System. We have helped startups across the world
              get their ideas to the market in record times without record
              costs.
            </div>
            <div
              className="mob-get-btn"
              onClick={(e) => {
                window.open(
                  "https://calendly.com/vivek-145/supernova-tech-incubation-discussion?month=2023-09",
                  "_blank"
                );
              }}
            >
              Book Meeting
            </div>
          </div>
          <div className="choose-div">
            <div className="gc-text">
              <div className="starfish-text">
                Supernova is the official product development program for the
                Garage Operating System. We have helped startups across the
                world get their ideas to the market in record times without
                record costs.
              </div>
              <div
                className="gs-btn"
                onClick={(e) => {
                  window.open(
                    "https://calendly.com/vivek-145/supernova-tech-incubation-discussion?month=2023-09",
                    "_blank"
                  );
                }}
              >
                Book Meeting
              </div>
            </div>
            <div className="choose-right-scroll-div">
              <div className="each-choose-div">
                <div className="linear-bg"></div>
                <div className="no-div">30</div>
                <div className="text-div">
                  Happy founders who have been able <br /> to bring their
                  products to life
                </div>
              </div>
              <div className="each-choose-div">
                <div className="linear-bg"></div>
                <div className="no-div">90</div>
                <div className="text-div">
                  Apps developed for our clients across <br /> web, mobile and
                  other platforms
                </div>
              </div>
              <div className="each-choose-div">
                <div className="linear-bg"></div>
                <div className="no-div">$1.5M+</div>
                <div className="text-div">
                  Money saved by our clients during their <br /> product
                  development process
                </div>
              </div>
              <div className="each-choose-div">
                <div className="linear-bg"></div>
                <div className="no-div">∞ Hrs</div>
                <div className="text-div">
                  The amount of time saved by our clients <br /> in bringing
                  their product to life
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="team-container">
          <div className="team-bg">
            <div
              className="image-div"
              style={{
                background: "white",
                borderRadius: "25px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  width: "90%",
                  height: "20px",
                  background: "white",
                  marginTop: "0px",
                  borderRadius: "25px",
                  left: "5%",
                  top: "2%",
                }}
              >
                &nbsp;
              </div>
              <img src={shorupan} alt="" />
              <div className="shorupan-text-div">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div className="shorupan-text">Shorupan P</div>
                  <div className="shorupanLinks">
                    <img
                      onClick={(e) =>
                        window.open("https://shorupan.com", "_blank")
                      }
                      src={shorupanLink1}
                      alt=""
                      style={{ width: "19px", height: "21px" }}
                    />
                    <img
                      onClick={(e) =>
                        window.open("https://instagram.com/shorupan", "_blank")
                      }
                      src={shorupanLink2}
                      alt=""
                      style={{ width: "23px", height: "23px" }}
                    />
                    <img
                      onClick={(e) =>
                        window.open(
                          "https://www.linkedin.com/in/shorupan/",
                          "_blank"
                        )
                      }
                      src={shorupanLink3}
                      alt=""
                      style={{ width: "25px", height: "24px" }}
                    />
                  </div>
                </div>
                <div className="des-text">Co-Founder Of Garage</div>
                <div
                  className="get-btn"
                  // onClick={() => {
                  //   navigate("/payment");
                  // }}
                  onClick={(e) => {
                    window.open(
                      "https://calendly.com/vivek-145/supernova-tech-incubation-discussion?month=2023-09",
                      "_blank"
                    );
                  }}
                >
                  Book Meeting
                </div>
              </div>
            </div>
            <div className="team-div">
              <div className="team-top-div">
                <div className="book-text">Book A 1:1 Consultation On Your</div>
                <div className="book-text-mob">
                  Book A 1:1 Consultation On Your Startup's
                </div>
                <div className="product-text gradient-text">
                  Product Development Strategy
                </div>
              </div>
              <div className="mob-image-div">
                <div
                  style={{
                    position: "absolute",
                    width: "90%",
                    height: "20px",
                    background: "white",
                    marginTop: "0px",
                    borderRadius: "25px",
                    left: "5%",
                    top: "2%",
                  }}
                >
                  &nbsp;
                </div>
                <img src={shorupan} alt="" />
                <div className="mob-shorupan-text-div">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div className="mob-shorupan-text">Shorupan P</div>
                    <div className="shorupanLinks">
                      <img
                        onClick={(e) =>
                          window.open("https://shorupan.com", "_blank")
                        }
                        src={shorupanLink1}
                        alt=""
                        style={{ width: "19px", height: "21px" }}
                      />
                      <img
                        onClick={(e) =>
                          window.open(
                            "https://instagram.com/shorupan",
                            "_blank"
                          )
                        }
                        src={shorupanLink2}
                        alt=""
                        style={{ width: "23px", height: "23px" }}
                      />
                      <img
                        onClick={(e) =>
                          window.open(
                            "https://www.linkedin.com/in/shorupan/",
                            "_blank"
                          )
                        }
                        src={shorupanLink3}
                        alt=""
                        style={{ width: "25px", height: "24px" }}
                      />
                    </div>
                  </div>
                  <div className="mob-des-text">Co-Founder Of Garage</div>
                  <div
                    className="get-btn"
                    // onClick={() => {
                    //   navigate("/payment");
                    // }}
                    onClick={(e) => {
                      window.open(
                        "https://calendly.com/vivek-145/supernova-tech-incubation-discussion?month=2023-09",
                        "_blank"
                      );
                    }}
                  >
                    Book Meeting
                  </div>
                </div>
              </div>
              <div className="team-bottom-div">
                <div className="about-text">About Shorupan</div>
                <div className="about-text1">
                  Shorupan entered the cryptocurrency space in 2017 with the
                  formation of the Nvest Group, a Canadian fin-tech holdings
                  company with the stated aim of incubating fin-tech startups.
                  In 2019 the Nvest Group gave birth to Global X Change. Since
                  then, Shorupan has served as CEO of the company overseeing the
                  development of the world’s first brokerage system for fin-tech
                  businesses and initiating the transition of GX into Garage as
                  the world's leading operating system for startups.{" "}
                  {/* <span className="read-more">Read More</span> */}
                </div>

                {/* <div
                  className="mob-get-btn"
                  // onClick={() => {
                  //   navigate("/payment");
                  // }}
                >
                  Get Your Product Built
                </div> */}
              </div>
            </div>
          </div>
        </div>

        <div className="case-study-container">
          <div className="case-text">Our Work</div>
          <div className="fin-text gradient-text">Speaks For Iteslf</div>
          <div className="case-div">
            {portfolioData?.map((e, i) => {
              return (
                <div className="each-case-div" key={i}>
                  <div
                    className="case-img-div"
                    onClick={() => {
                      // console.log(e, "portfolio data");
                      setSelectedProfileData(e);
                      localStorage.setItem(
                        "selectedPortfolio",
                        JSON.stringify(e)
                      );
                      navigate(`/casestudies/${e?.brandName}`);
                    }}
                  >
                    <img src={e?.logo} alt={e?.logo} />
                  </div>
                  <div className="case-text-div1">{e?.brandName}</div>
                  <div className="case-text-div2">{e?.about}</div>
                  <div className="countries-div">
                    <div className="each-country1">
                      <span>
                        <img src={e?.countryIcon} alt="" />
                      </span>
                      <span>{e?.countryName}</span>
                    </div>
                    <div className="each-country">#{e?.industry}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="industries-container">
          <div className="background-section">
            <div className="industries-text">
              Our Specialty - Bringing It All Together
            </div>
            <div className="industries-text-mob-container">
              <div className="industries-text-mob1">Our Specialty</div>
              <div className="industries-text-mob2 gradient-text">
                Bringing It All Together
              </div>
            </div>
            <div className="industries-div">
              <div className="gc-text">
                <div className="speciality-text">
                  Our team specializes in bringing together all the skills you
                  would otherwise purchase individually. We employ all our
                  resources and experience on your six month development cycle
                  as opposed to billing you for each service.{" "}
                </div>
                <div
                  className="gs-btn"
                  onClick={(e) => {
                    window.open(
                      "https://calendly.com/vivek-145/supernova-tech-incubation-discussion?month=2023-09",
                      "_blank"
                    );
                  }}
                >
                  Book Meeting
                </div>
              </div>
              <div className="right-scroll-div">
                {specialityData.map((speciality) => {
                  return (
                    <div className="each-div" key={speciality.id}>
                      <div className="each-div-top-area">
                        <div
                          className="heading"
                          dangerouslySetInnerHTML={{
                            __html: speciality.heading,
                          }}
                        ></div>
                      </div>
                      <div className="each-div-bottom-area">
                        {speciality.points.map((point, index) => (
                          <div className="each-point" key={index}>
                            <div className="bullet-img">
                              <img src={bullet} alt="" />
                            </div>
                            <div>{point}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="work-main" >
          <div className="work-with-container" ref={divRef} >
            <div className="work-with-div"
              style={{
                paddingLeft: `calc(${(divSize?.width - 1300) / 2}px - 0px)`
              }}
            >
              <div className="work-with-text-container"

              >
                <div className="work-with-text1">Your App</div>
                <div className="work-with-text2 gradient-text">
                  What Will You Get?
                </div>
              </div>
              <div
                className={`${wwOption === "Client Web App"
                  ? "ww-text1 gradient-text"
                  : "ww-text2 gradient-text"
                  } colorChanger`}
                onClick={() => {
                  setWwOption("Client Web App");
                }}
              >
                Client Web App
              </div>

              <div
                className={`${wwOption === "Client Mobile Apps"
                  ? "ww-text1 gradient-text"
                  : "ww-text2 gradient-text"
                  } colorChanger`}
                onClick={() => {
                  setWwOption("Client Mobile Apps");
                }}
              >
                Client Mobile Apps
              </div>

              <div
                className={`${wwOption === "Robust Backend"
                  ? "ww-text1 gradient-text"
                  : "ww-text2 gradient-text"
                  } colorChanger`}
                onClick={() => {
                  setWwOption("Robust Backend");
                }}
              >
                Robust Backend
              </div>

              <div
                className={`${wwOption === "Admin Dashboards"
                  ? "ww-text1 gradient-text"
                  : "ww-text2 gradient-text"
                  } colorChanger`}
                onClick={() => {
                  setWwOption("Admin Dashboards");
                }}
              >
                Admin Dashboards
              </div>

              <div
                className={`${wwOption === "DevOps & Security"
                  ? "ww-text1 gradient-text"
                  : "ww-text2 gradient-text"
                  } colorChanger`}
                onClick={() => {
                  setWwOption("DevOps & Security");
                }}
              >
                DevOps & Security
              </div>

              <div
                className={`${wwOption === "3rd Party Integrations"
                  ? "ww-text1 gradient-text"
                  : "ww-text2 gradient-text"
                  } colorChanger`}
                onClick={() => {
                  setWwOption("3rd Party Integrations");
                }}
              >
                3rd Party Integrations
              </div>

              <div className="ww-option-container-mob">
                <div
                  onClick={() => {
                    setWwOption("Client Web App");
                  }}
                  className={`${wwOption === "Client Web App"
                    ? "ww-mob-selected"
                    : "ww-mob-default"
                    }`}
                >
                  Client Web App
                </div>

                <div
                  onClick={() => {
                    setWwOption("Client Mobile Apps");
                  }}
                  className={`${wwOption === "Client Mobile Apps"
                    ? "ww-mob-selected"
                    : "ww-mob-default"
                    }`}
                >
                  Client Mobile Apps
                </div>

                <div
                  onClick={() => {
                    setWwOption("Robust Backend");
                  }}
                  className={`${wwOption === "Robust Backend"
                    ? "ww-mob-selected"
                    : "ww-mob-default"
                    }`}
                >
                  Robust Backend
                </div>

                <div
                  onClick={() => {
                    setWwOption("Admin Dashboards");
                  }}
                  className={`${wwOption === "Admin Dashboards"
                    ? "ww-mob-selected"
                    : "ww-mob-default"
                    }`}
                >
                  Admin Dashboards
                </div>

                <div
                  onClick={() => {
                    setWwOption("DevOps & Security");
                  }}
                  className={`${wwOption === "DevOps & Security"
                    ? "ww-mob-selected"
                    : "ww-mob-default"
                    }`}
                >
                  DevOps & Security
                </div>

                <div
                  onClick={() => {
                    setWwOption("3rd Party Integrations");
                  }}
                >
                  3rd Party Integrations
                </div>
              </div>
            </div>
            <div className="ww-right-div"
              style={{

                paddingRight: `calc(${(divSize?.width - 1300) / 2}px - 0px)`
              }}
            >
              <div className="right-top">
                <div className="right-top-title">Delivered</div>
                <div className="right-top-subtitle gradient-text">
                  Without Record Costs
                </div>
              </div>
              <div className="marginleft">
                <div className="cardContainer">
                  <div className="card">
                    <div className="cardTitle">Monthly Instalments</div>
                    <div className="cardAmount gradient-text">$5,000.00</div>
                    <div className="cardDuration">
                      Per Month For{" "}
                      <span
                        className="durationSpan gradient-text"
                        style={{ textDecoration: "underline" }}
                      >
                        6 MONTHS
                      </span>
                    </div>
                    <div className="listStyle">
                      <img src={pointIcon} alt="" />
                      <div>Cancel at any time</div>
                    </div>
                    <div className="listStyle">
                      <img src={pointIcon} alt="" />
                      <div>Get 6 extra monthls of AMC for free after release</div>
                    </div>
                    <div
                      className="cardButton"
                      onClick={(e) => {
                        window.open(
                          "https://calendly.com/vivek-145/supernova-tech-incubation-discussion?month=2023-09",
                          "_blank"
                        );
                      }}
                    >
                      Book Meeting
                    </div>
                  </div>
                  <div
                    className="card"
                    style={{
                      color: "white",
                      background:
                        "linear-gradient(179.39deg, #F05C4D 10.56%, #B580F2 105.06%)",
                    }}
                  >
                    <div className="cardTitle" style={{ color: "white" }}>
                      Express
                    </div>
                    <div className="cardAmount" style={{ color: "white" }}>
                      $30,000.00
                    </div>
                    <div className="cardDuration" style={{ color: "white" }}>
                      Made In 2 Payments
                    </div>
                    <div className="listStyle">
                      <img src={pointIconWhite} alt="" />
                      <div>$15,000.00 due upon initiation</div>
                    </div>
                    <div className="listStyle">
                      <img src={pointIconWhite} alt="" />
                      <div>$15,000.00 due upon completion of the project</div>
                    </div>
                    <div
                      onClick={(e) => {
                        window.open(
                          "https://calendly.com/vivek-145/supernova-tech-incubation-discussion?month=2023-09",
                          "_blank"
                        );
                      }}
                      className="cardButton1"
                      style={{ color: "white", border: "1px solid white" }}
                    >
                      Book Meeting
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="ww-right-div">
            <div className="ww-right-top">
              <div className="departments">
                <div
                  className={
                    department === "Research & Development"
                      ? "each-dep1"
                      : "each-dep"
                  }
                  onClick={() => {
                    setDepartment("Research & Development");
                  }}
                >
                  Research & Development
                  <div className="arr-div">
                    <img
                      src={
                        department === "Research & Development"
                          ? arrWhite
                          : arrBlack
                      }
                      alt=""
                    />
                  </div>
                </div>
                <div
                  className={
                    department === "Scoping & Design" ? "each-dep1" : "each-dep"
                  }
                  onClick={() => {
                    setDepartment("Scoping & Design");
                  }}
                >
                  Scoping & Design
                  <div className="arr-div">
                    <img
                      src={
                        department === "Scoping & Design" ? arrWhite : arrBlack
                      }
                      alt=""
                    />
                  </div>
                </div>
                <div
                  className={
                    department === "Architecture" ? "each-dep1" : "each-dep"
                  }
                  onClick={() => {
                    setDepartment("Architecture");
                  }}
                >
                  Architecture
                  <div className="arr-div">
                    <img
                      src={department === "Architecture" ? arrWhite : arrBlack}
                      alt=""
                    />
                  </div>
                </div>
                <div
                  className={
                    department === "UI Design" ? "each-dep1" : "each-dep"
                  }
                  onClick={() => {
                    setDepartment("UI Design");
                  }}
                >
                  UI Design
                  <div className="arr-div">
                    <img
                      src={department === "UI Design" ? arrWhite : arrBlack}
                      alt=""
                    />
                  </div>
                </div>
                <div
                  className={
                    department === "Frontend Development"
                      ? "each-dep1"
                      : "each-dep"
                  }
                  onClick={() => {
                    setDepartment("Frontend Development");
                  }}
                >
                  Frontend Development
                  <div className="arr-div">
                    <img
                      src={
                        department === "Frontend Development"
                          ? arrWhite
                          : arrBlack
                      }
                      alt=""
                    />
                  </div>
                </div>
                <div
                  className={
                    department === "Backend Development"
                      ? "each-dep1"
                      : "each-dep"
                  }
                  onClick={() => {
                    setDepartment("Backend Development");
                  }}
                >
                  Backend Development
                  <div className="arr-div">
                    <img
                      src={
                        department === "Backend Development"
                          ? arrWhite
                          : arrBlack
                      }
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="description">
                Experimenting with ideas and technologies which may not be a
                product. Software Development- Working on a specific product
                desired by any client or based on market demand.
              </div>
            </div>
            <div className="ww-right-bottom">
              <div className="data-box">
                <iframe
                  title="YouTube Video"
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/_QMGJAd6mHU"
                  frameBorder="0"
                  allowFullScreen
                ></iframe>
              </div>
              <div className="background-div"></div>
            </div>
          </div> */}
          </div>
        </div>
        {/* <div className="wat-u-get-container">
          <div className="wat-u-get-div">
            <div className="wat-u-get-text">The Supernova Process</div>
          </div>
          <div className="boxes-container">
            {boxesData.map((e, i) => {
              const modifiedSubhead = e.subhead
                .split("Here")
                .join(
                  '<span style="background: linear-gradient(90deg, #59A2DD 0%, #B580F2 100%); background-clip: text; -webkit-background-clip: text; -webkit-text-fill-color: transparent;">Here</span>'
                )
                .split("STA Term Sheet")
                .join(
                  '<span style="background: linear-gradient(90deg, #59A2DD 0%, #B580F2 100%); background-clip: text; -webkit-background-clip: text; -webkit-text-fill-color: transparent;">STA Term Sheet</span>'
                );
              return (
                <div className="each-box" key={i}>
                  <div className="each-box-img">
                    <img src={e.icon} alt="" />
                  </div>
                  <div className="box-head">{e.title}</div>
                  <div
                    className="box-sub-head"
                    dangerouslySetInnerHTML={{ __html: modifiedSubhead }}
                  />
                </div>
              );
            })}
          </div>
        </div> */}

        {/* <div className="about-company-container">
          <div className="about-company-div">
            <div className="about-company-text">About our company</div>
            <div>
              <img src={star1} alt="" />
            </div>
          </div>
          <div className="about-company-box">
            <div className="about-image">
              <img src={about} alt="" />
            </div>
            <div className="ac-right-div">
              <div className="ac-right-box"></div>
              <div className="ac-right-text-div">
                {qnAndAns.map((e, i) => {
                  return (
                    <div className="each-qn" key={i}>
                      <div className="qn">{e.ques}</div>
                      <div className="ans">{e.answer}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="testimonial-section">
          <div className="testimonial-div">
            <div className="testimonial-text">
              Businesses of all sizes succeed with Supernova
            </div>
            <div className="testimonial-mob-txt">
              Hear From Supernova Entrepreneurs
            </div>
            <div className="testimonial-star">
              <img src={star} alt="" />
            </div>
          </div>
          <div className="testimonial-container">
            {testimonialData.map((e, i) => {
              return (
                <div className="each-testimonial" key={i}>
                  <div className="testimonial-img">
                    <img src={e.pic} alt="" />
                  </div>
                  <div className="testimonial-img-mob">
                    <img src={mobdummy} alt="" />
                  </div>
                  <div className="testimonial-data">
                    <div className="quotee">
                      <img src={quote} alt="" />
                    </div>
                    <div className="review">{e.content}</div>
                    <div className="name">Name</div>
                    <div className="des">Designation</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div> */}

        {/* <div className="max-section">
          <div className="max-container">
            <div className="max-text-div">
              <div className="text">
                <div className="left-star">
                  <img src={star} alt="" />
                </div>
                <div>Maximize ROI, Maximize Results</div>
              </div>
              <div className="text">
                <div className="right-star">
                  <img src={star} alt="" />
                </div>
                <div>Let your spends justify your returns</div>
              </div>
            </div>
            <div className="max-text-div-mob">
              <div className="text">
                <div className="right-star">
                  <img src={star} alt="" />
                </div>
                <div>Maximize ROI,</div>
              </div>
              <div className="text">
                <div>Maximize Results</div>
              </div>
              <div className="text">
                <div>Let your spends </div>
              </div>
              <div className="text">
                <div>justify your returns</div>
              </div>
            </div>
            <div className="book-btn">Get Started Now</div>
          </div>
        </div> */}
        <div
          className="footer-container-main"
        >
          <div className="footer-container">
            <div className="footer-logo-div">
              <div className="footer-logo">
                <img src={footerlogo} alt="" />
              </div>
              <div className="mob-footer-logo">
                <img src={footerLogoMob} alt="" />
              </div>
              <div className="partner-social-div">
                <div className="footer-text1">
                  Garage - A <span>Starfish Partners</span> Initiative
                </div>
                <div className="social-logos">
                  <div>
                    <img
                      src={utube}
                      alt=""
                      onClick={(e) =>
                        window.open(
                          "https://www.youtube.com/@unlockgarage",
                          "_blank"
                        )
                      }
                    />
                  </div>
                  <div>
                    <img
                      src={insta}
                      alt=""
                      onClick={(e) =>
                        window.open(
                          "https://www.instagram.com/unlockgarage/",
                          "_blank"
                        )
                      }
                    />
                  </div>
                  <div>
                    <img
                      src={twitter}
                      alt=""
                      onClick={(e) =>
                        window.open("https://twitter.com/unlockgarage", "_blank")
                      }
                    />
                  </div>
                  <div>
                    <img
                      src={linkedin}
                      alt=""
                      onClick={(e) =>
                        window.open(
                          "https://www.linkedin.com/company/unlockgarage",
                          "_blank"
                        )
                      }
                    />
                  </div>
                  <div>
                    <img
                      src={telegram}
                      alt=""
                      onClick={(e) =>
                        window.open("https://t.me/+917416708117", "_blank")
                      }
                    />
                  </div>
                  <div>
                    <img
                      src={whatsapp}
                      alt=""
                      onClick={(e) =>
                        window.open(
                          "https://wa.me/message/7UJKNGMEHKT4B1",
                          "_blank"
                        )
                      }
                    />
                  </div>
                  <div>
                    <img
                      src={fb}
                      alt=""
                      onClick={(e) =>
                        window.open(
                          "https://www.facebook.com/UnlockGarageOS",
                          "_blank"
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <>
              <div className="opportunities-div mob-mar-top">
                <div className="opp-head" style={{ fontWeight: "500" }}>
                  Marketsverse
                </div>
                <div
                  className="footer-text1"
                  onClick={(e) =>
                    window.open("https://publications.app", "_blank")
                  }
                >
                  Publications
                </div>
                <div
                  className="footer-text1"
                  onClick={(e) => window.open("https://classrooms.app", "_blank")}
                >
                  Classrooms
                </div>
                <div
                  className="footer-text1"
                  onClick={(e) =>
                    window.open("https://publications.app", "_blank")
                  }
                >
                  Web3
                </div>
                <div
                  className="footer-text1"
                  onClick={(e) => window.open("https://malls.app", "_blank")}
                >
                  Malls
                </div>
                <div
                  className="footer-text1"
                  onClick={(e) => window.open("https://firms.app", "_blank")}
                >
                  Firms
                </div>
                <div
                  className="footer-text1"
                  onClick={(e) =>
                    window.open("https://experiences.group", "_blank")
                  }
                >
                  Experiences
                </div>
              </div>
              <div className="opportunities-div">
                <div className="opp-head">Garage</div>
                <div
                  className="footer-text1"
                  onClick={(e) =>
                    window.open("https://unlockgarage.com", "_blank")
                  }
                >
                  UnlockGarage
                </div>
                <div
                  className="footer-text1"
                  onClick={(e) =>
                    window.open("https://startupbrokers.com", "_blank")
                  }
                >
                  StartupBrokers
                </div>
                <div
                  className="footer-text1"
                  onClick={(e) => window.open("https://viral.group", "_blank")}
                >
                  Viralverse
                </div>
                {/* <div className="footer-line"></div> */}
                <div
                  className="footer-text1"
                  onClick={(e) => window.open("https://socially.app", "_blank")}
                >
                  Socially
                </div>
                <div className="footer-text1">#1MillionDollarStartup</div>
              </div>
              <div className="opportunities-div">
                <div className="opp-head">Other</div>
                <div className="footer-text1">Privacy Policy</div>
                <div className="footer-text1">Disclaimer</div>
                <div className="footer-text1">Terms & Conditions</div>
                {/* <div className="footer-line"></div> */}
                <div className="footer-text1">Call Us @ +16475590183</div>
                <div className="footer-text1">inquiries@unlockgarage.com</div>
              </div>
            </>

            <div className="footer-logo-div1">
              <div className="footer-logo">
                <img src={footerlogo} alt="" />
              </div>
              <div className="mob-footer-logo">
                <img src={footerLogoMob} alt="" />
              </div>
              <div className="partner-social-div">
                <div className="footer-text1">
                  Garage - A{" "}
                  <span
                    onClick={(e) =>
                      window.open("https://starfishgp.com", "_blank")
                    }
                  >
                    Starfish Partners
                  </span>{" "}
                  Initiative
                </div>
                <div className="social-logos">
                  <div>
                    <img
                      src={utube}
                      alt=""
                      onClick={(e) =>
                        window.open(
                          "https://www.youtube.com/@unlockgarage",
                          "_blank"
                        )
                      }
                    />
                  </div>
                  <div>
                    <img
                      src={insta}
                      alt=""
                      onClick={(e) =>
                        window.open(
                          "https://www.instagram.com/unlockgarage/",
                          "_blank"
                        )
                      }
                    />
                  </div>
                  <div>
                    <img
                      src={twitter}
                      alt=""
                      onClick={(e) =>
                        window.open("https://twitter.com/unlockgarage", "_blank")
                      }
                    />
                  </div>
                  <div>
                    <img
                      src={linkedin}
                      alt=""
                      onClick={(e) =>
                        window.open(
                          "https://www.linkedin.com/company/unlockgarage",
                          "_blank"
                        )
                      }
                    />
                  </div>
                  <div>
                    <img
                      src={telegram}
                      alt=""
                      onClick={(e) =>
                        window.open("https://t.me/+917416708117", "_blank")
                      }
                    />
                  </div>
                  <div>
                    <img
                      src={whatsapp}
                      alt=""
                      onClick={(e) =>
                        window.open(
                          "https://wa.me/message/7UJKNGMEHKT4B1",
                          "_blank"
                        )
                      }
                    />
                  </div>
                  <div>
                    <img
                      src={fb}
                      alt=""
                      onClick={(e) =>
                        window.open(
                          "https://www.facebook.com/UnlockGarageOS",
                          "_blank"
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div></div>
      </div>
      {showPopup && window.innerWidth > 800 ? (
        <div className="overlay" onClick={(e) => setShowPopup(false)}>
          <div className="popup" onClick={(e) => e.stopPropagation()}>
            <div className="entrepreneurs">
              <div className="text">
                {selectedBrand?.name == "startupBrokers" ? "For Affiliates" : "For Entrepreneurs"}   </div>
            </div>
            <div className="imgRow">
              {/* {brands.map((item, index) => (
                <div className="imgCol">
                  <img
                    onClick={(e) => setSelectedBrand(item)}
                    src={item?.logo}
                    alt=""
                    style={{
                      opacity: selectedBrand?.name === item?.name ? 1 : 0.3,
                    }}
                  />
                  <div className="verticalDiv">&nbsp;</div>
                </div>
              ))} */}
              <div className="imgCol">
                <img
                  onClick={(e) => setSelectedBrand(brands[0])}
                  src={brands[0]?.logo}
                  alt=""
                  style={{
                    opacity: selectedBrand?.name === brands[0]?.name ? 1 : 0.3,
                  }}
                />
              </div>
              <div className="verticalDiv">&nbsp;</div>
              <div className="imgCol">
                <img
                  onClick={(e) => setSelectedBrand(brands[1])}
                  src={brands[1]?.logo}
                  alt=""
                  style={{
                    opacity: selectedBrand?.name === brands[1]?.name ? 1 : 0.3,
                  }}
                />
              </div>
              {/* <div className="verticalDiv">&nbsp;</div>
              <div className="imgCol">
                <img
                  onClick={(e) => setSelectedBrand(brands[2])}
                  src={brands[2]?.logo}
                  alt=""
                  style={{
                    opacity: selectedBrand?.name === brands[2]?.name ? 1 : 0.3,
                  }}
                />
              </div> */}
            </div>
            <div className="brandDesc">{selectedBrand?.text}</div>
            {selectedBrand?.name === "supernova" ? (
              <div className="popupButton">Currently Selected</div>
            ) : selectedBrand?.name === "startupBrokers" ? (
              <div
                className="popupButton"
                onClick={(e) => {
                  window.open("https://startupbrokers.com/", "_blank");
                }}
              >
                Learn More
              </div>
            ) : (
              <div className="popupButton">Coming Soon</div>
            )}
          </div>
        </div>
      ) : showPopup && window.innerWidth < 800 ? (
        <div className="overlayMobile" onClick={(e) => setShowPopup(false)}>
          <div className="popupMobile" onClick={(e) => e.stopPropagation()}>
            <div>
              {brands.map((item) => {
                if (item.name === selectedBrand.name) {
                  return (
                    <div className="brandnav">
                      <img
                        src={leftArrow}
                        alt=""
                        onClick={(e) => handlePrev(item)}
                      />
                      <img
                        src={selectedBrand?.logo}
                        alt=""
                        style={{ width: "14rem", height: "3rem" }}
                      />
                      <img
                        src={rightArrow}
                        alt=""
                        onClick={(e) => handleNext(item)}
                      />
                    </div>
                  );
                }
              })}

              <div className="brandDesc">{selectedBrand?.text}</div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {selectedBrand?.name === "supernova" ? (
                <div className="popupButton">Currently Selected</div>
              ) : selectedBrand?.name === "startupBrokers" ? (
                <div
                  className="popupButton"
                  onClick={(e) => {
                    window.open("https://startupbrokers.com/", "_blank");
                  }}
                >
                  Learn More
                </div>
              ) : (
                <div className="popupButton">Coming Soon</div>
              )}
            </div>
          </div>
        </div>
      ) : null}

      {showDropdown ? (
        <div className="overlay1" onClick={(e) => setShowDropdown(false)}>
          <div className="dropdownContent" onClick={(e) => e.stopPropagation()}>
            <div className="innerdropcontenct">
              <div style={{ width: "17rem" }}>
                {dropdownItems.map((item) => (
                  <p
                    className={
                      selectedDropdown === item.name
                        ? "selectedNavItem"
                        : "navItem"
                    }
                    onClick={(e) => {
                      setSelectedDropdown(item.name);
                    }}
                    onMouseEnter={(e) => setHoveredDropDown(item.name)}
                    onMouseOut={(e) => setHoveredDropDown(selectedDropdown)}
                  >
                    {item?.name}
                  </p>
                ))}
              </div>

              {dropdownItems
                .find((o) => o.name === hoveredDropDown)
                .cards.map((item) => (
                  <div
                    className="dropdownCard"
                    onClick={(e) =>
                      item?.link
                        ? item?.title !== "Supernova" &&
                        window.open(item?.link, "_blank")
                        : null
                    }
                  >
                    <div className="cardTitle">{item?.title}</div>
                    <div className="cardDesc">{item?.desc}</div>
                    <div className="cardFooter">
                      <img src={arrowRight} alt="" />
                      <img src={item?.img} alt="" />
                    </div>
                  </div>
                ))}
              {/* {selectedDropdown === "Product Dev" ? (
              <>
                <div className="dropdownCard">
                  <div className="cardTitle">Supernova</div>
                  <div className="cardDesc">
                    Buy and sell tokenized shares of private companies in
                    minutes
                  </div>
                  <div className="cardFooter">
                    <img src={arrowRight} alt="" />
                    <img src={card1Image} alt="" />
                  </div>
                </div>
                <div className="dropdownCard">
                  <div className="cardTitle">Marketsverse</div>
                  <div className="cardDesc">
                    Choose from a set of pre-built application templates for
                    your business.
                  </div>
                  <div className="cardFooter">
                    <img src={arrowRight} alt="" />
                    <img src={card2Image} alt="" />
                  </div>
                </div>
              </>
            ) : (
              ""
            )} */}
            </div>
          </div></div>
      ) : (
        ""
      )
      }
      {
        window.innerWidth < 800 ? (
          <div
            className={showDropdownMobile ? "overlay" : ""}
            onClick={(e) => setShowDropdownMobile(!showDropdownMobile)}
          >
            <div
              className="dropdownMobile"
              onClick={(e) => {
                if (showDropdownMobile) {
                  e.stopPropagation();
                }
              }}
            >
              <div
                onClick={(e) => setShowDropdownMobile(true)}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  paddingBottom: showDropdownMobile ? "20px" : "",
                  paddingTop: showDropdownMobile ? "10px" : "",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                }}
              >
                <div
                  className="selectedMobileDropdown"
                  style={{
                    fontWeight: showDropdownMobile ? "700" : "",
                    fontSize: showDropdownMobile ? "1.2rem" : "1rem",
                  }}
                  onClick={(e) => {
                    if (showDropdownMobile) {
                      setSelectedDropdown(selectedDropdown);
                    } else {
                      setShowDropdownMobile(true);
                    }
                  }}
                >
                  {selectedDropdown?.toUpperCase()}
                </div>
                <img
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowDropdownMobile(!showDropdownMobile);
                  }}
                  src={arrowDown}
                  alt=""
                  style={{
                    width: "8.5px",
                    height: "6.5px",
                    marginLeft: "10px",
                    cursor: "pointer",
                    objectFit: "contain",
                    transform: !showDropdownMobile ? "rotate(180deg)" : "",
                  }}
                />
              </div>
              {showDropdownMobile ? (
                <>
                  <div>
                    {dropdownItems.map(
                      (item) =>
                        item.name !== selectedDropdown && (
                          <p
                            className={
                              selectedDropdown === item.name
                                ? "selectedNavItem"
                                : "navItem"
                            }
                            onClick={(e) => {
                              setSelectedDropdown(item.name);
                            }}
                            onMouseEnter={(e) => setHoveredDropDown(item.name)}
                            onMouseOut={(e) =>
                              setHoveredDropDown(selectedDropdown)
                            }
                            style={{ paddingLeft: "20px", paddingRight: "20px" }}
                          >
                            {item?.name}
                          </p>
                        )
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      overflowX: "scroll",
                      padding: "25px 0px",
                      paddingLeft: "20px",
                    }}
                  >
                    {dropdownItems
                      .find((o) => o.name === selectedDropdown)
                      .cards.map((item) => (
                        <div>
                          <div
                            onClick={(e) =>
                              item?.title !== "Supernova" &&
                              window.open(item?.link, "_blank")
                            }
                            className="dropdownCard"
                            style={{ background: "white", height: "236px" }}
                          >
                            <div className="cardTitle">{item?.title}</div>
                            <div className="cardDesc">{item?.desc}</div>
                            <div className="cardFooter">
                              <img
                                src={arrowRight}
                                alt=""
                                onClick={(e) =>
                                  item?.title !== "Supernova" &&
                                  window.open(item?.link, "_blank")
                                }
                              />
                              <img src={item?.img} alt="" />
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          ""
        )
      }
    </div >
  );
};

export default HomePage;
