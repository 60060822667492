import React, { useContext, useEffect, useState } from "react";
import chevronRight from "../img/chevron-right.png";
import arrowRightSolid from "../img/arrow-right-solid.svg";
import { GlobalContext } from "../context/globalContext";
import { mobilePortfolio } from "../data/mobileportfoliodata";
import { Link } from "react-router-dom";

export const PortfolioContainer = () => {
  const { mobilePortfolioData, setMobilePortfolioData } =
    useContext(GlobalContext);
  const [count, setCount] = useState(0);

  const moveForward = () => {
    if (count == mobilePortfolio.length - 1) {
      setCount(0);
    } else {
      setCount(count + 1);
    }
  };

  const moveBackward = () => {
    if (count == 0) {
      setCount(mobilePortfolio.length - 1);
    } else {
      setCount(count - 1);
    }
  };

  useEffect(() => {
    setMobilePortfolioData(mobilePortfolio[count]);
  }, [count, setMobilePortfolioData]);

  return (
    <section className="Portfolio__container" id="portfolio">
      <div className="Portfolio__content">
        <div className="Portfolio__content__left">
          <div className="Portfolio__content__left__phone">
            <div
              id="PortfolioSlider"
              className="Portfolio__content__left__phone__content"
            >
              <img
                alt="mobileimage"
                src={mobilePortfolioData && mobilePortfolioData.img}
              />
            </div>
          </div>
        </div>

        <div className="Portfolio__content__right">
          <button
            onClick={moveBackward}
            className="Portfolio__content__right__prev"
          >
            <img src={chevronRight} alt="chevronRight" />
          </button>
          <span className="spacer"> </span>
          <button
            onClick={moveForward}
            className="Portfolio__content__right__next"
          >
            <img src={chevronRight} alt="chevronRight" />
          </button>
          <h3>OUR PORTFOLIO</h3>
          <h2 id="PortfolioTitle">
            {mobilePortfolioData && mobilePortfolioData.header}
          </h2>
          <p id="PortfolioText">
            {mobilePortfolioData && mobilePortfolioData.subHeader}
          </p>
          <Link
            id="PorfolioLink"
            to={
              mobilePortfolioData
                ? `/legacy/portfolio/${mobilePortfolioData.url}`
                : "/legacy/portfolio"
            }
            className="CaseStudyButton"
          >
            <img
              src={arrowRightSolid}
              draggable="false"
              alt="arrowRightSolid"
            />{" "}
            See Case Study
          </Link>
          <Link to="/legacy/portfolio/" className="CaseStudyButton">
            <img
              src={arrowRightSolid}
              draggable="false"
              alt="arrowRightSolid"
            />{" "}
            See Full Portfolio
          </Link>
        </div>
      </div>
    </section>
  );
};
