import React from "react";
import facebook from "../img/facebook-f-brands.svg";
import linkedin from "../img/linkedin-in-brands.svg";

export const FooterContainer = () => {
  return (
    <section class="Footer__container">
      <div class="Footer__content">
        <ul>
          <li>
            <a href="#services">Services</a>
          </li>
          <li>
            <a href="/legacy/portfolio" title="Our Portfolio">
              Our Portfolio
            </a>
          </li>
          <li>
            <a href="/legacy/#process">Our Process</a>
          </li>
          <li>
            <a href="/legacy/#pricing">Pricing Model</a>
          </li>
        </ul>

        <p>
          <a class="FooterSocial" href="https://www.facebook.com/GravitiChain/">
            <button class="Hero__social__facebook">
              <img src={facebook} draggable="false" alt="facebook" />
            </button>
          </a>
          <a
            class="FooterSocial"
            href="https://www.linkedin.com/company/gravitichain/"
          >
            <button class="Hero__social__linkedin">
              <img src={linkedin} draggable="false" alt="linkedin" />
            </button>
          </a>
          © 2018 Gravitichain studios. All rights reserved.
          <br />
          <a
            class="FooterSocial --mobile"
            href="https://www.facebook.com/GravitiChain/"
          >
            <button class="Hero__social__facebook">
              <img src={facebook} draggable="false" alt="facebook" />
            </button>
          </a>
          <a
            class="FooterSocial --mobile"
            href="https://www.linkedin.com/company/gravitichain/"
          >
            <button class="Hero__social__linkedin">
              <img src={linkedin} draggable="false" alt="linkedin" />
            </button>
          </a>
        </p>
      </div>
    </section>
  );
};
